<template>
  <h1>Anagram</h1>
  <form id="searchbox" v-on:submit.prevent="onSubmit">
    <input id="search" type="text" placeholder="Type here" v-model="input">
    <button id="submit" @click="getData()">Search</button>
  </form>
  <table id="result" v-if="result">
    <td v-if="!Object.entries(result).length"><b>None</b></td>
    <tr v-for="(tags, name) in result" v-bind:key="name">
      <td class="category"><h4>{{ name }} </h4></td>
      <td class="element">
        <li class='tag' v-for="tag in tags" v-bind:key="tag">{{ tag }}</li>
      </td>
    </tr>
  </table>
</template>

<script lang="ts">
import { AxiosResponse, AxiosError } from 'axios'
import { useToast } from "vue-toastification";
import { Options, Vue } from 'vue-class-component';
import AnagramService from '@/services/AnagramService';

@Options({
  props: {
    msg: String
  }
})
export default class AnagramInput extends Vue {
  input: string = "";
  result: any = null;
  toast: any;

  created() {
    this.toast = useToast();
  }

  getData() {
    AnagramService.getAnagrams(this.input).then((response: AxiosResponse) => {
      this.result = response;
      if (!Object.entries(response).length) {
        this.toast.warning('No results.');
      }
    }).catch((e: AxiosError) => {
      if (e.response!.status === 400) {
        this.toast.warning(e.response!.data);
      }
      console.log(e);
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
