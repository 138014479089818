<template>
  <a href="https://cwoebker.com" class="by-cwoebker" title="cwoebker">
    <img alt="avatar" src="./assets/avatar.png"> cwoebker
  </a>
  <div class="anagram-container">
    <AnagramInput />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HelloWorld from './components/HelloWorld.vue';
import AnagramInput from './components/AnagramInput.vue'

@Options({
  components: {
    HelloWorld,
    AnagramInput
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
@import 'assets/css/styles.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.anagram-container {
  margin-top: 40px;
}

</style>
